<template>
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on" label-position="left">
      <!-- {{loginForm}} -->
      <div class="title-container">
        <h3 class="title">德誠資訊 團購系統後台</h3>
        <p class="logo">
          <!-- <el-image :src="require('@/assets/logo/護聯資訊_logo.png')" style="max-width:220px;"> -->
          <!-- <el-image :src="require('@/assets/logo/德誠資訊logo_3.png')" style="max-width:220px;">
            <div slot="placeholder" class="image-slot">
              loading<span class="dot">...</span>
            </div>
          </el-image> -->
          <!-- <el-image :src="require('@/assets/logo/元復復健體系×元動力物理治療所.jpg')" style="max-width:220px;">
            <div slot="placeholder" class="image-slot">
              loading<span class="dot">...</span>
            </div>
          </el-image> -->
        </p>

        <h5 class="version_title">{{version}}</h5>
      </div>
      <el-form-item prop="partner_id">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          ref="partner_id"
          v-model="loginForm.partner_id"
          placeholder="合作夥伴統編(無不需填寫)"
          name="partner_id"
          type="text"
        />
      </el-form-item>
      <el-form-item prop="username">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          ref="username"
          v-model="loginForm.username"
          placeholder="帳號"
          name="username"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
      </el-form-item>


      <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
        <el-form-item prop="password">
          <span class="svg-container">
            <svg-icon icon-class="password" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="密碼"
            name="password"
            tabindex="2"
            autocomplete="on"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
          />
          <span class="show-pwd" @click="showPwd">
            <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
          </span>
        </el-form-item>
      </el-tooltip>
      <el-form-item prop="validator">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          ref="validator"
          v-model="loginForm.validator"
          placeholder="一次性驗證碼(可不須填寫)"
          name="一次性驗證碼"
          type="text"
          tabindex="1"
          autocomplete="on"
          @keyup.enter.native="handleLogin"
        />
      </el-form-item>
      <div style="position:relative;text-align:center; padding:10px auto;">
        <div class="tips">
          <transition name="el-fade-in">
            <span style="color:red;" v-show="show_otp_message" class="transition-box">一次性驗證碼將會發送至已綁定合作夥伴line群組或信箱</span>
          </transition>
        </div>
        <div class="tips">
        <!-- <vue-recaptcha ref="recaptcha" @verify="handleLogin" @expired="onCapchaExpired" :sitekey="google_recapache" /> -->
        </div>
        <div class="tips">
        <!-- <vue-recaptcha ref="recaptcha" @verify="handleLogin" @expired="onCapchaExpired" :sitekey="google_recapache" /> -->
          <el-checkbox v-model="checkbox_remember_me" class="filter-item" style="margin-left:15px;">
            記住我
          </el-checkbox>
        </div>
        <div class="tips">
          <!-- 判斷驗證碼為預設9999，將不會顯示發送OTP驗證碼 -->
        <el-button type="primary" :loading="otploading" v-show="loginForm.validator!=9999" @click="getOTPvalidator">發送OTP驗證碼</el-button>
        <el-button :loading="loading" type="primary" @click.native.prevent="handleLogin">登入</el-button>
        </div>
      </div>
      <div style="position:relative">
        <!-- <div class="tips">
          <span>Username : admin</span>
          <span>Password : any</span>
        </div>
        <div class="tips">
          <span style="margin-right:18px;">Username : editor</span>
          <span>Password : any</span>
        </div> -->

        <!-- <el-button class="thirdparty-button" type="primary" @click="showDialog=true">
          Or connect with
        </el-button> -->
      </div>
    </el-form>

    <!-- <el-dialog title="Or connect with" :visible.sync="showDialog">
      Can not be simulated on local, so please combine you own business simulation! ! !
      <br>
      <br>
      <br>
      <social-sign />
    </el-dialog> -->
  </div>
</template>

<script>
import Cookies from 'js-cookie'
// import { validUsername } from '@/utils/validate'
// import SocialSign from './components/SocialSignin'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
export default {
  name: 'Login',
  data() {
    // const validateUsername = (rule, value, callback) => {
    //   if (!validUsername(value)) {
    //     callback(new Error('Please enter the correct user name'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      loginForm: {
        partner_id:'',
        username: '',
        password: '',
        validator: '',
        device_token: '',
        recaptcha_token:''
      },
      loginRules: {
        username: [{ required: true,message: '帳號為必填欄位' }],
        password: [
          { required: true,message: '密碼為必填欄位'},
          // { required: true,message: '密碼必須為數字', pattern:/^[0-9\s]*$/ }
        ],
        validator: [
          // { required: true,message: '一次性驗證碼為必填欄位' },
          { message: '一次性驗證碼必須為數字', pattern:/^[0-9\s]*$/ },
          { message: '一次性驗證碼必須為4位數字', pattern:/^[0-9\s]{4}$/ }
        ]
      },
      passwordType: 'password',
      capsTooltip: false,
      otploading: false,
      loading: false,
      showDialog: false,
      // 顯示otp_message
      show_otp_message: false,
      redirect: undefined,
      otherQuery: {},
      version:'',
      checkbox_remember_me:false
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        console.log("168",route)
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          console.log("this.redirect",this.redirect)
          this.otherQuery = this.getOtherQuery(query)
          console.log("this.otherQuery",this.otherQuery)
        }
      },
      immediate: true
    }
  },
  created() {
    // window.addEventListener('storage', this.afterQRScan)
    // console.log(process.env.VUE_APP_BASE_API);
    // console.log(process.env.VUE_APP_BASE_API_TRANSFER);

    // 得知使用者唯一裝置token
    this.get_web_device_token()
    //顯示所在環境按鈕
    this.version = process.env.VUE_APP_BASE_API_ENV
    console.log(Cookies.get('checkbox_remember_me'))
    if(Cookies.get('username_login')!=undefined){
      this.loginForm.partner_id = Cookies.get('partner_id_login')
      this.loginForm.username = Cookies.get('username_login')
      this.checkbox_remember_me = true
    }
  },
  mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      var _self = this
      _self.$refs.loginForm.validate(valid => {
        if (valid) {
          _self.loading = true
        grecaptcha.ready(function() {
          grecaptcha.execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_WEB_HASH_KEY, {action: 'login_groupbuy'}).then(async function(token) {
            _self.loginForm.recaptcha_token = token
            await _self.$store.dispatch('user/login', _self.loginForm)
              .then((res) => {
                  console.log("res",res)
                  console.log(res.message=="登入成功，請設定OTP推播方式")
                  // 第一次登入判斷
                  if(res.message=="登入成功，請設定OTP推播方式"){
                    _self.$router.push({ path: '/profile/index'})
                    console.log("res.message",res.message)
                  }
                  else if(res.success==true){
                  if(_self.checkbox_remember_me==true){
                    Cookies.set('partner_id_login',_self.loginForm.partner_id)
                    Cookies.set('username_login',_self.loginForm.username)
                    Cookies.set('checkbox_remember_me',_self.checkbox_remember_me)
                  }
                  if(_self.checkbox_remember_me==false){
                    Cookies.remove('partner_id_login')
                    Cookies.remove('username_login')
                    Cookies.remove('checkbox_remember_me')
                  }
                  _self.$router.push({ path: _self.redirect || '/', query: _self.otherQuery })
                  }
                  _self.loading = false
              })
              .catch(() => {
                _self.loading = false
                return false
              })
          });
        });
        } else {
          console.log('有資料未填寫!!')
          return false
        }
      })
    },
    // 發送OTP驗證碼
    getOTPvalidator() {
      var _self = this
      _self.otploading = true
      grecaptcha.ready(function() {
        grecaptcha.execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_WEB_HASH_KEY, {action: 'otp'}).then(async function(token) {
          _self.loginForm.recaptcha_token = token
          _self.$store.dispatch('user/loginotp', _self.loginForm)
          .then((res) => {
            console.log(res)
            if(res.success==true){
              // 顯示otp_message
              _self.show_otp_message = true
              _self.otploading = false
            }
            else{
              _self.otploading = false
            }
          }).catch(() => {
            _self.otploading = false
            return false
          })
        })
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    // 得知裝置瀏覽器token
    get_web_device_token() {
      const fpPromise = FingerprintJS.load();(async () => {
        // Get the visitor identifier when you need it.
        const fp = await fpPromise
        const result = await fp.get()
        // This is the visitor identifier:
        const visitorId = result.visitorId
        this.loginForm.device_token = visitorId
        console.log(visitorId)
      })()
    }
    // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }
  }
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg:#283443;
$light_gray:#fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg:#2d3a4b;
$dark_gray:#889aa4;
$light_gray:#eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 16px;
    color: #fff;
    margin: 18px auto;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
    .version_title{
      background-color:#F5F5F5;
      width: 80px;
      height: 30px;
      position: absolute;
      right:10px;
      bottom:-70px;
      color: red;
      text-align: center;
      font-size: 23px;
    }
    .logo{
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
</style>
